<template>
  <input
    type="date"
    :value="value"
    v-bind="$attrs"
    @input="updateDate"
    class="form-control input_color"
  />
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: {
      type: String
    }
  },

  methods: {
    updateDate(event) {
      this.$emit('input', event.target.value);
    }
  }
};
</script>

<style></style>
