<template>
  <b-form-select v-model="newSelect" :options="options" class="input_color"></b-form-select>
</template>

<script>
export default {
  model: {
    prop: 'selectModel',
    event: 'setSelected'
  },
  props: {
    selectModel: {
      type: [String, Number]
    },
    options: {
      type: Array
    }
  },

  computed: {
    newSelect: {
      get() {
        return this.selectModel;
      },
      set(value) {
        this.$emit('setSelected', value);
      }
    }
  }
};
</script>

<style></style>
