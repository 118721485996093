<template>
  <div>
    <base-spinner
      v-if="loadingReport"
      :isRequestSpinner="true"
      :isBaseSpinner="false"
    ></base-spinner>
    <base-table
      v-else-if="!loadingReport && isReport"
      :fields="columns"
      :items="rows.rowsData"
      :current-page="currentPage"
      :per-page="10"
      :sortBy="sortBy"
      :isFooter="true"
      :isHeader="isReportType"
    >
      <template #headerText>
        {{ table.ReportType.Name }} за период с {{ showOnlyDate(table.IntervalStart) }} по
        {{ showOnlyDate(table.IntervalEnd) }}
      </template>
      <template #cell(id)="{item}">
        <router-link
          :to="{
            name: 'ReportDetails',
            params: item.id.Attributes.Parameters,
            query: createUrlQuery(item.id.Attributes.Parameters)
          }"
          target="_blank"
        >
          {{ item.id.Value }}
        </router-link>
      </template>
      <template #cell(IncomingCount)="{item}">
        <router-link
          :to="{
            name: 'ReportDetails',
            query: createUrlQuery(item.IncomingCount.Attributes.Parameters)
          }"
          :class="$style.linkDetails"
          target="_blank"
        >
          {{ item.IncomingCount.Value }}
        </router-link>
      </template>
      <template #cell(OutgoingCount)="{item}">
        <router-link
          :to="{
            name: 'ReportDetails',
            query: createUrlQuery(item.OutgoingCount.Attributes.Parameters)
          }"
          :class="$style.linkDetails"
          target="_blank"
        >
          {{ item.OutgoingCount.Value }}
        </router-link>
      </template>
      <template #cell(MissedCount)="{item}">
        <router-link
          :to="{
            name: 'ReportDetails',
            query: createUrlQuery(item.MissedCount.Attributes.Parameters)
          }"
          :class="$style.linkDetails"
          target="_blank"
        >
          {{ item.MissedCount.Value }}
        </router-link>
      </template>
      <template #footer>
        <b-pagination
          v-if="isPagination"
          v-model="currentPage"
          :total-rows="table.Rows[0].RowsTotal"
          :per-page="10"
          align="center"
          size="Default"
          class="my-0"
          @input="showPageData(currentPage)"
        ></b-pagination>
      </template>
    </base-table>
    <base-table v-if="isEmptyReport && !loadingReport" :isHeader="isReportType">
      <template #headerText>
        {{ table.ReportType.Name }} за период с {{ showOnlyDate(table.IntervalStart) }} по
        {{ showOnlyDate(table.IntervalEnd) }}
      </template>
      <slot>Данных не найдено</slot>
    </base-table>
  </div>
</template>

<script>
import BaseTable from '@/components/shared/BaseTable.vue';
import BaseSpinner from '@/components/shared/BaseSpinner.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      sortBy: 'Дата и время',
      currentPage: 1
    };
  },

  components: {
    BaseTable,
    BaseSpinner
  },

  computed: {
    ...mapGetters({
      columns: 'reports/columns',
      table: 'reports/table',
      rows: 'reports/rows',
      requestData: 'reports/requestData',
      loadingReport: 'loadingReport'
    }),

    isPagination() {
      return (
        this.table.Rows[0]?.RowsTotal > this.requestData.PageSize
        && this.requestData.PageSize <= 100
      );
    },

    isReportType() {
      return this.table.ReportType !== null;
    },

    // Проверяет не пустая ли таблица отчета
    isReport() {
      return this.table.RowsTotal > 0
        ? this.table.Rows[0]?.RowsTotal > 0
        : false;
    },

    // Проверяет пустая ли таблица отчета. Трубется для отображения сообщения об отсутствии отчета
    isEmptyReport() {
      return (
        this.table.RowsTotal === 0
        || this.table.Rows === null
        || (Object.keys(this.table).length > 0 && this.table.Rows[0]?.RowsTotal === 0)
      );
    }
  },

  beforeUpdate() {
    // Сбрасываем страницу пагинации на 1
    if (this.loadingReport) this.currentPage = 1;
  },

  beforeDestroy() {
    this.CLEAR_TABLE();
  },

  methods: {
    ...mapActions({ getCalls: 'reports/getCalls' }),
    ...mapMutations({ CLEAR_TABLE: 'reports/CLEAR_TABLE' }),

    showOnlyDate(date) {
      if (date.includes('T23:59:59')) return date.replace(/T23:59:59/, '');
      return date.replace(/T00:00:00/, '');
    },

    showPageData() {
      this.getCalls({ vm: this, params: this.requestData, page: this.currentPage });
    },

    createUrlQuery(params) {
      return {
        Filter: params.Filter,
        StartDateTimeStr: params.StartDateTimeStr,
        EndDateTimeStr: params.EndDateTimeStr,
        PageNumber: params.PageNumber,
        PageSize: params.PageSize,
        ReportId: params.SelectedReportId
      };
    }
  }
};
</script>

<style lang="scss" module>
.linkDetails {
  color: $linkBlue;
  text-decoration: underline;
}
</style>
