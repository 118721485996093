var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadingReport)?_c('base-spinner',{attrs:{"isRequestSpinner":true,"isBaseSpinner":false}}):(!_vm.loadingReport && _vm.isReport)?_c('base-table',{attrs:{"fields":_vm.columns,"items":_vm.rows.rowsData,"current-page":_vm.currentPage,"per-page":10,"sortBy":_vm.sortBy,"isFooter":true,"isHeader":_vm.isReportType},scopedSlots:_vm._u([{key:"headerText",fn:function(){return [_vm._v(" "+_vm._s(_vm.table.ReportType.Name)+" за период с "+_vm._s(_vm.showOnlyDate(_vm.table.IntervalStart))+" по "+_vm._s(_vm.showOnlyDate(_vm.table.IntervalEnd))+" ")]},proxy:true},{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
          name: 'ReportDetails',
          params: item.id.Attributes.Parameters,
          query: _vm.createUrlQuery(item.id.Attributes.Parameters)
        },"target":"_blank"}},[_vm._v(" "+_vm._s(item.id.Value)+" ")])]}},{key:"cell(IncomingCount)",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{class:_vm.$style.linkDetails,attrs:{"to":{
          name: 'ReportDetails',
          query: _vm.createUrlQuery(item.IncomingCount.Attributes.Parameters)
        },"target":"_blank"}},[_vm._v(" "+_vm._s(item.IncomingCount.Value)+" ")])]}},{key:"cell(OutgoingCount)",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{class:_vm.$style.linkDetails,attrs:{"to":{
          name: 'ReportDetails',
          query: _vm.createUrlQuery(item.OutgoingCount.Attributes.Parameters)
        },"target":"_blank"}},[_vm._v(" "+_vm._s(item.OutgoingCount.Value)+" ")])]}},{key:"cell(MissedCount)",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{class:_vm.$style.linkDetails,attrs:{"to":{
          name: 'ReportDetails',
          query: _vm.createUrlQuery(item.MissedCount.Attributes.Parameters)
        },"target":"_blank"}},[_vm._v(" "+_vm._s(item.MissedCount.Value)+" ")])]}},{key:"footer",fn:function(){return [(_vm.isPagination)?_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.table.Rows[0].RowsTotal,"per-page":10,"align":"center","size":"Default"},on:{"input":function($event){return _vm.showPageData(_vm.currentPage)}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()]},proxy:true}])}):_vm._e(),(_vm.isEmptyReport && !_vm.loadingReport)?_c('base-table',{attrs:{"isHeader":_vm.isReportType},scopedSlots:_vm._u([{key:"headerText",fn:function(){return [_vm._v(" "+_vm._s(_vm.table.ReportType.Name)+" за период с "+_vm._s(_vm.showOnlyDate(_vm.table.IntervalStart))+" по "+_vm._s(_vm.showOnlyDate(_vm.table.IntervalEnd))+" ")]},proxy:true}],null,false,1759704941)},[_vm._t("default",[_vm._v("Данных не найдено")])],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }