// Общие для дат
export const DaysPerMonth = (year, month) => new Date(year, month, 0).getDate();

// Выбор месяца введенного пользователем
export const GetUserMonth = (date) => date.substring(5, 7);

// Выбор дня введенного пользователем
export const GetUserDay = (date) => date.substring(8);

const setStartDay = (start, end) => {
  if (GetUserDay(start) > GetUserDay(end)) return GetUserDay(start);
  return GetUserDay(end);
};

const setEndDay = (start, end) => {
  if (GetUserDay(start) > GetUserDay(end)) return GetUserDay(end);
  return GetUserDay(start);
};

const setLastDay = (start, end) => {
  if (DaysPerMonth(start.substring(0, 4), GetUserMonth(start)) <= setStartDay(start, end)) {
    return DaysPerMonth(start.substring(0, 4), GetUserMonth(start));
  }
  return setStartDay(start, end);
};

const formatDate = (date, increment = 0) => {
  const newDate = date + increment;
  const nextDate = newDate + 1;
  if (`${newDate}`.length < 2 && nextDate === 10) return `0${newDate}`;
  if (`${nextDate}`.length < 2) return `0${newDate}`;
  return `${newDate}`;
};

// Даты
export const ShowMonth = () => formatDate(new Date().getMonth(), 1);

export const ShowDay = () => formatDate(new Date().getDate());

export const ShowCurrentDate = () => `${new Date().getFullYear()}-${ShowMonth()}-${ShowDay()}`;

// Время
export const ShowHours = () => formatDate(new Date().getHours());

export const ShowMinutes = () => formatDate(new Date().getMinutes());

export const ShowSeconds = () => formatDate(new Date().getSeconds());

// Форматирование дат начала периода и конца. чтобы они были всегда в переделах одного месяца
export const SetDateIntervalEnd = (start, end) => `${start.substring(0, 4)}-${GetUserMonth(start)}-${setLastDay(start, end)}`;

export const SetDateIntervalStart = (start, end) => `${end.substring(0, 4)}-${GetUserMonth(end)}-${setEndDay(start, end)}`;
