<template>
  <div>
    <b-form @submit.stop.prevent="getReport">
      <b-row>
        <b-col lg="7" xl="6">
          <b-form-group
            label="Начало интервала"
            label-cols-sm="4"
            label-cols-lg="6"
            label-cols-xl="5"
          >
            <BaseInputDate
              v-model="IntervalStart"
              @input="changeDateIntervalEnd()"
            />
          </b-form-group>
          <b-form-group
            label="Конец интервала"
            label-cols-sm="4"
            label-cols-lg="6"
            label-cols-xl="5"
          >
            <BaseInputDate
              v-model="IntervalEnd"
              @input="changeDateIntervalStart()"
            />
          </b-form-group>
          <b-form-group
            id="input-group-3"
            label="Форма отчёта"
            label-cols-sm="5"
            label-cols-md="4"
            label-cols-xl="5"
            label-for="input-3"
          >
            <BaseSelect v-model="reportType" :options="options"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="isButton">
        <b-col lg="7" xl="6">
          <b-form-group
            v-for="(field, index) in reportForm.FormFields"
            :key="field.Label"
            :label="field.Label"
            label-cols-sm="5"
            label-cols-md="4"
            label-cols-lg="7"
            label-cols-xl="5"
          >
            <BaseTextInput
              v-if="field.Type === 'string' || field.Type === 'int'"
              v-model="reportFormData[index].value"
            />
            <base-checkbox
              v-if="field.Type === 'CheckBox'"
              v-model="reportFormData[index].value"
              :isSwitch="isSwitch"
              :size="size"
            ></base-checkbox>
            <BaseSelect
              v-if="field.Type === 'box'"
              v-model="reportFormData[index].value"
              :options="field.Elements"
            />
          </b-form-group>
          <b-form-group
            label="Записей на страницу"
            label-cols-sm="5"
            label-cols-md="4"
            label-cols-lg="7"
            label-cols-xl="5"
          >
            <BaseSelect v-model="perPage" :options="optionsItemsPerPage"/>
          </b-form-group>
        </b-col>
      </b-row>
      <BaseButton v-show="isButton">
        <template #text>
          Показать отчет
        </template>
      </BaseButton>
    </b-form>
  </div>
</template>

<script>
import BaseSelect from '@/components/shared/inputs/BaseSelect.vue';
import BaseButton from '@/components/shared/buttons/BaseButton.vue';
import {
  ShowMonth,
  ShowDay,
  ShowCurrentDate,
  SetDateIntervalEnd,
  SetDateIntervalStart
} from '@/additional_helpers/dates';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import BaseInputDate from '@/components/shared/inputs/BaseInputDate.vue';

export default {
  props: {
    options: {
      type: Array,
      require: true
    },
    params: {
      type: Array,
      require: true
    }
  },

  model() {
    return {
      prop: 'model',
      event: 'setModel'
    };
  },

  data() {
    return {
      size: 'lg',
      isSwitch: true,
      reportType: 'Выберите отчет',
      perPage: '10',
      reportFormData: [],
      optionsItemsPerPage: [
        { text: '10', value: '10' },
        { text: '25', value: '25' },
        { text: '50', value: '50' },
        { text: '100', value: '100' },
        { text: 'все', value: 2147483647 }
      ],
      IntervalStart: ShowCurrentDate(),
      IntervalEnd: ShowCurrentDate()
    };
  },

  components: {
    BaseInputDate,
    BaseSelect,
    BaseButton,
    BaseTextInput: () => import('@/components/shared/inputs/BaseTextInput.vue'),
    BaseCheckbox: () => import('@/components/shared/inputs/BaseCheckbox.vue')
  },

  mounted() {
    ShowMonth();
    ShowDay();
  },

  beforeDestroy() {
    this.SHOW_LOADING_SPINNER(false);
  },

  watch: {
    reportType() {
      this.reportFormData = this.model.map((el) => ({ ...el }));
    }
  },

  computed: {
    ...mapGetters({ currentPage: 'reports/currentPage', table: 'reports/table' }),

    // Рассчитывает отображать кнопку submit или нет
    isButton() {
      return this.reportType !== this.options[0].text;
    },

    // Находит выбранный пользователем отчет
    reportForm() {
      const a = this.params.find((el) => el.Name === this.reportType);
      // преобразует имена полей фильтра для отправки на сервер
      a.FormFields.forEach((el, index) => {
        const type = el.Type.toLowerCase();
        const firstSymbol = a.FormFields[index].Name.substring(0, 1);

        if (type === 'string' && firstSymbol !== 's') {
          a.FormFields[index].Name = `s${el.Name}`;
        }
        if (type === 'checkbox' && firstSymbol !== 'c') {
          a.FormFields[index].Name = `c${el.Name}`;
        }
        if (type === 'int' && firstSymbol !== 'i') {
          a.FormFields[index].Name = `i${el.Name}`;
        }
        if (type === 'box' && firstSymbol !== 'b') {
          a.FormFields[index].Name = `b${el.Name}`;
        }
      });
      return a;
    },

    model() {
      const fieldsCount = this.reportForm.FormFields;
      return fieldsCount.map((el) => {
        if (el.DefaultValue === '0') {
          return { name: el.Name, value: false };
        }
        if (el.DefaultValue === '1') {
          return { name: el.Name, value: true };
        }
        if (!el.DefaultValue && el.Type === 'box') {
          return { name: el.Name, value: el.Elements[0].value };
        }
        return { name: el.Name, value: el.DefaultValue };
      });
    },

    // создаёт фильтр для добавления в объект для отправки на сервер
    filter() {
      const filterKey = this.reportFormData.map((el) => el.name);
      const filterValue = Object.values(this.reportFormData.map((el) => el.value));
      const filter = filterKey.map((el, index) => {
        if (typeof filterValue[index] === 'boolean') {
          return `${el}:${filterValue[index]}`;
        }
        if (typeof filterValue[index] !== 'boolean' && filterValue[index]) {
          if (el === 'bInternalServiceId') {
            return `${el}:${filterValue[index]}`;
          }
          return `${el}:%${filterValue[index]}`;
        }
        return '';
      });
      return filter.filter((el) => el !== '').join(';');
    }
  },

  methods: {
    ...mapActions({ getCalls: 'reports/getCalls' }),
    ...mapMutations({ SHOW_LOADING_SPINNER: 'SHOW_LOADING_SPINNER' }),

    changeDateIntervalEnd() {
      this.IntervalEnd = SetDateIntervalEnd(this.IntervalStart, this.IntervalEnd);
    },

    changeDateIntervalStart() {
      this.IntervalStart = SetDateIntervalStart(this.IntervalStart, this.IntervalEnd);
    },

    createRequestBody() {
      return {
        IntervalStart: `${this.IntervalStart}T00:00:00`,
        IntervalEnd: `${this.IntervalEnd}T00:00:00`,
        ReportId: this.reportForm.Id,
        PageSize: +this.perPage,
        OrderByCol: 1,
        Filter: this.filter
      };
    },

    getReport() {
      const row = this.createRequestBody();
      this.SHOW_LOADING_SPINNER(true);
      this.getCalls({ vm: this, params: row });
    }
  }
};
</script>

<style></style>
