<template>
  <the-wrapper :isSpinner="isSpinner" :isFullWidth="isFullWidth">
    <slot>
      <ServicesDescription :isFooter="isFooter" :isSubtitle="isSubtitle">
        <template #title>Отчеты {{ serviceName }}</template>
        <template #subtitle>
          Пожалуйста, выберите интервал (в пределах одного и того же месяца) и форму отчёта.
        </template>
        <template #body>
          <div v-if="isNotAvailableReports">{{ notAvailableReportsMessage }}</div>
          <form-request
            v-else
            :params="params"
            :options="options"
          ></form-request>
        </template>
      </ServicesDescription>
      <br />
      <all-reports></all-reports>
    </slot>
  </the-wrapper>
</template>

<script>
import TheWrapper from '@/components/shared/TheWrapper.vue';
import AllReports from '@/components/reports/AllReports.vue';
import FormRequest from '@/components/reports/FormRequest.vue';
import ServicesDescription from '@/components/shared/ServicesDescription.vue';
import { getDetailsWithQuery } from '@/api/common';
import { catchFunction } from '@/additional_helpers/helpers';
import URL from '@/basUrl';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      isFullWidth: true,
      isSpinner: true,
      isFooter: false,
      isSubtitle: true,
      notAvailableReportsMessage: '',
      reportService: this.$route.query.service,
      params: [],
      options: [{ text: 'Выберите отчет', value: 'Выберите отчет', disabled: true }]
    };
  },

  components: {
    AllReports,
    TheWrapper,
    ServicesDescription,
    FormRequest
  },

  // При переходе на другую услугу обновляет форму
  beforeRouteUpdate(to, from, next) {
    this.clearData(to.query.service);
    next();
    if (this.isNotAvailableReports) this.notAvailableReportsMessage = '';
    this.getReportsList();
  },

  beforeDestroy() {
    this.CLEAR_TABLE();
  },

  created() {
    this.getReportsList();
  },

  computed: {
    serviceName() {
      const { name } = this.$route.query;
      return name.replace(/_/, ' ');
    },

    isNotAvailableReports() {
      return this.notAvailableReportsMessage.length;
    }
  },

  methods: {
    ...mapMutations({
      SHOW_LOADING: 'SHOW_LOADING',
      CLEAR_TABLE: 'reports/CLEAR_TABLE'
    }),

    // Получает список доступных форм отчета
    getReportsList() {
      getDetailsWithQuery(URL.REPORTS_LIST, `service=${this.reportService}`)
        .then((result) => {
          if (result.status === 404) {
            this.notAvailableReportsMessage = JSON.parse(result.data).Error;
            this.SHOW_LOADING(false);
            return;
          }
          this.params.push(...result.data);
          const newOptions = result.data.map((el) => ({ text: el.Name, value: el.Name }));
          this.options.push(...newOptions);
          this.SHOW_LOADING(false);
        })
        .catch((e) => catchFunction(e));
    },

    clearData(query) {
      this.CLEAR_TABLE();
      this.params = [];
      this.reportService = query;
      this.options = [{ text: 'Выберите отчет', value: 'Выберите отчет', disabled: true }];
    }
  }
};
</script>

<style></style>
