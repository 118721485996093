<template>
  <div
    class="table table_wrap"
    :class="{ radiusUnset: !isHeader, [$style.tableMarginBot]: !isBotMargin }"
  >
    <div v-if="isHeader" class="table-header_wrap dflex">
      <div class="table-header_left dflex">
        <div class="header-text">
          <slot name="headerText"></slot>
        </div>
      </div>
      <div class="table-header_right">
        <span>
          <slot name="headerRight"></slot>
        </span>
      </div>
    </div>
    <div class="table-body_wrap dflex" :class="{ [$style.tablePadding]: !isVisible }">
      <b-col class=" dflex">
        <slot name="additional"></slot>
        <slot></slot>
        <b-table
          v-if="isVisible"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortByValue"
          bordered
          outlined
          hover
          class="text-center"
        >
          <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
          <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
            <slot :name="slot" v-bind="scope"></slot>
          </template>
        </b-table>
      </b-col>
    </div>
    <div v-if="isFooter" class="table-footer_wrap dflex">
      <b-col>
        <slot name="footer"></slot>
      </b-col>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'sortBy',
    event: 'setSortBy'
  },
  props: {
    // формат fields: [{ key: 'key1', label: 'label' }, { key: 'key2', label: '' }]
    fields: {
      type: Array,
      require: true
    },
    items: {
      type: Array || Object,
      require: true
    },
    isVisible: {
      type: Boolean,
      require: false,
      default: true
    },
    isFooter: {
      type: Boolean,
      require: false,
      default: false
    },
    sortBy: {
      type: String,
      require: false
    },
    isHeader: {
      type: Boolean,
      require: false,
      default: true
    },
    isBotMargin: {
      type: Boolean,
      require: false,
      default: true
    }
  },

  computed: {
    sortByValue: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.$emit('setSortBy', value);
      }
    }
  }
};
</script>

<style lang="scss">
.table {
  &_wrap {
    border: 1px solid $lightGrey;
    // border-radius: 0.25rem 0.25rem 0 0;

    .container {
      max-width: 120rem;
    }
  }
}

.table-header_wrap {
  background-color: $coreUiLightGrey;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-bottom: 1px solid $lightGrey;
  overflow: auto;
  justify-content: space-between;
}

.table-header {
  align-items: center;
}

.header-icon {
  align-items: center;
}

.header-text {
  padding-left: 0.25rem;
  font-weight: 600;
  font-size: 0.875rem;
}

.table-body_wrap {
  padding-top: 1rem;
  background: $white;
  overflow: auto;
}

.table-footer_wrap {
  padding-bottom: 1rem;
  background: $white;
  overflow: auto;
}

.radiusUnset {
  border-radius: unset;
}

@media (min-width: 576px) {
  .header-text {
    font-size: 1rem;
  }
}
</style>

<style module>
.tablePadding {
  padding-bottom: 1rem;
}

.tableMarginBot {
  margin-bottom: 0;
}
</style>
